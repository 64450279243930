import i18next, { i18n } from 'i18next';
import { initReactI18next, TFunction } from 'react-i18next';
import EN from './locales/en-us.json';
import RU from './locales/ru-rus.json';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

i18next
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage'],
      caches: ['localStorage'],
    },
    resources: {
      en: {
        translation: EN,
      },
      ru: {
        translation: RU,
      },
    },
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
  });

export interface Customi18n extends i18n {
  t: TFunction;
}

type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];
export type TLocaleKey = NestedKeyOf<typeof EN>;

export default i18next as Customi18n;
