import { PERMISSIONS } from 'shared/config/constants';
import { EReportTableType } from 'shared/types/table';

export enum ERoutePermissions {
  MAIN_COMISSION = 'MAIN_COMISSION',
  MAIN_SETTINGS = 'MAIN_SETTINGS',
  MAIN_USERS = 'MAIN_USERS',
  MAIN_ROLES = 'MAIN_ROLES',
  MAIN_SCOPES = 'MAIN_SCOPES',
  MAIN_PERMISSIONS = 'MAIN_PERMISSIONS',
  MAIN_PROFILE = 'MAIN_PROFILE',
  MAIN_STORAGE_CONTAINERS = 'MAIN_STORAGE_CONTAINERS',
  MAIN_VMS = 'MAIN_VMS',
  MAIN_PROJECTS = 'MAIN_PROJECTS',
}

export const routesPermissions = {
  [ERoutePermissions.MAIN_USERS]: PERMISSIONS.USERS,
  [ERoutePermissions.MAIN_SETTINGS]: PERMISSIONS.USERS,
  [ERoutePermissions.MAIN_ROLES]: PERMISSIONS.ROLES,
  [ERoutePermissions.MAIN_SCOPES]: PERMISSIONS.SCOPES,
  [ERoutePermissions.MAIN_PERMISSIONS]: PERMISSIONS.PERMISSIONS,
  [ERoutePermissions.MAIN_VMS]: PERMISSIONS.NUTANIX,
  [ERoutePermissions.MAIN_PROJECTS]: PERMISSIONS.NUTANIX,
  [ERoutePermissions.MAIN_PROFILE]: PERMISSIONS.PROFILE,
};

const reportTableTypes = Object.assign({ ...EReportTableType });
delete reportTableTypes.SERVICE_ACCOUNTS;
delete reportTableTypes.INVESTED_FUNDS_PORTFOLIO_POSITION;
export const defiStatisticsPages = Object.keys(reportTableTypes).map((item) =>
  item.replaceAll('_', '-').toLowerCase(),
);
