import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const wrapper = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
`;

const dotsContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40px;

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: dot-bounce 1.4s infinite ease-in-out both;
    ${theming((theme) => ({ backgroundColor: theme.colors.white }))}
  }

  .dot:nth-child(2) {
    animation-delay: 0.32s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.64s;
  }

  @keyframes dot-bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
  }
`;

export const threeDotsCss = {
  wrapper,
  dotsContainer,
};
