import React, { Fragment, Suspense } from 'react';
import { useUnit } from 'effector-react';
import { Outlet, useParams } from 'react-router-dom';

import { routesPermissions } from 'processes/Router/config';
import { TRouteGuard } from './types';
import { profileModel } from 'entities/Profile/model';

const RouteGuard: React.FC<TRouteGuard> = ({
  component: Component,
  isOutlet = false,
  permissionKey,
  hidePaths,
  ...rest
}) => {
  const [user, selfPermissions] = useUnit([
    profileModel.selfUser.stores.$selfUser,
    profileModel.selfUser.stores.$selfPermissions,
  ]);
  const params = useParams();
  const hideComponent =
    hidePaths?.length &&
    Object.keys(params).length &&
    hidePaths.some((item) => Object.keys(params).includes(item));

  if (!user) return null;
  if (
    permissionKey &&
    !Object.values(routesPermissions[permissionKey]).find((title) =>
      selfPermissions.find((selfPermission) => selfPermission.title === title),
    )
  )
    return null;

  return (
    <Suspense fallback={<Fragment />}>
      {Component && !hideComponent && <Component {...rest} />}
      <Outlet />
    </Suspense>
  );
};

export default RouteGuard;
