import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { Text } from 'shared/ui/atoms/Text';
import { getLinkItemName } from './getLinkItemName';
import { $itemIdName, getItemIdName } from './model/breadCrumbsItemId';

import { breadcrumbsCss } from './style';

export const Breadcrumbs = () => {
  const [itemIdName] = useUnit([$itemIdName]);
  const location = useLocation();
  const params = useParams();
  let currentLink = '';

  useEffect(() => {
    const paramsKeys = Object.keys(params);
    if (paramsKeys.length) getItemIdName(paramsKeys[0]);
  }, [location.pathname]);

  const crumbs = location.pathname
    .split('/')
    .filter((link) => link !== '')
    .map((link, index, arr) => {
      const paramsEntries = Object.entries(params)?.[0];
      currentLink += `/${link}`;
      return (
        <Link key={link} to={currentLink}>
          <Text className={breadcrumbsCss.linkName}>
            {getLinkItemName(paramsEntries, link, itemIdName, arr, index)}
          </Text>
        </Link>
      );
    });

  return (
    <div className={breadcrumbsCss.breadcrumbs}>
      {crumbs?.length ? crumbs : ''}
    </div>
  );
};
