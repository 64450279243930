import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'shared/config/constants';
import { parseJwtToken } from '../parseJwtToken';

export const extractUserId = (): number => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (!token) {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    window.location.reload();
  }
  const decoded = parseJwtToken(token);
  return decoded.sub;
};
