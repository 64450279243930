import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/ui/atoms/Button';
import IconLogo from 'shared/assets/images/logo.svg?react';
import { confirmEmailModel } from './model';
import { useUnit } from 'effector-react';
import { match } from 'ts-pattern';
import { EEffectState } from 'shared/lib/stateEffect/types';
import Spinner from 'shared/ui/atoms/Spinner';
import { Card } from 'shared/ui/atoms/Card';
import { Text } from 'shared/ui/atoms/Text';
import { Link } from '@chakra-ui/react';

export const ConfirmEmail = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const state = useUnit(confirmEmailModel.confirm.confirmEmailState.$state);

  const onConfirm = async () => {
    const token = params.get('token');
    if (token) confirmEmailModel.confirm.events.confirmEmail(token);
  };

  return (
    <Card
      props={{
        width: '600px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <IconLogo width={100} />
      <Text props={{ marginBlock: 4 }} size='xx-large'>
        {t('confirmEmail.title')}
      </Text>
      <Text props={{ marginBottom: 6, textAlign: 'center' }} size='large'>
        {t('confirmEmail.prompt')}
      </Text>
      {match(state)
        .with(EEffectState.IDLE, () => (
          <Button props={{ width: 40 }} onClick={onConfirm}>
            {t('confirmEmail.button')}
          </Button>
        ))
        .with(EEffectState.PENDING, () => <Spinner />)
        .with(EEffectState.SUCCESS, () => (
          <Link href='/' textAlign='center' color='blue.500'>
            <p>{t('confirmEmail.success')}</p>
          </Link>
        ))
        .otherwise(() => (
          <Button props={{ width: 40 }} onClick={onConfirm}>
            {t('confirmEmail.button')}
          </Button>
        ))}
    </Card>
  );
};
