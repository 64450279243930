import { createEvent, sample } from 'effector';
import { notificationsModel } from 'entities/Notification/model';

const initCache = createEvent();
const resetCache = createEvent();

sample({
  clock: initCache,
  target: [notificationsModel.notifications.events.initNotificationsCache],
});

sample({
  clock: resetCache,
  target: [notificationsModel.notifications.events.resetNotificationCache],
});

export const cache = {
  events: {
    initCache,
    resetCache,
  },
};
