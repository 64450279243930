import { createEffect, createEvent, sample } from 'effector';

import { requestsClient } from 'shared/api/client';

import {
  DeleteRoleMutation,
  DeleteRoleMutationVariables,
} from 'shared/api/apollo/__generated__';
import { modalFactory } from 'shared/lib/modalFactory';
import { toastEffect } from 'shared/lib/toastEffect';
import { roles } from './roles';

// ----------------------- Delete role -----------------------
const roleDeleteModal = modalFactory<number>(null);

const deleteRole = createEvent();
const deleteRoleFx = toastEffect(
  createEffect<DeleteRoleMutationVariables, DeleteRoleMutation>(
    async (params) => {
      return await requestsClient.deleteRole(params);
    },
  ),
  {
    errorMessage: 'roles.errors.delete',
    successMessage: 'roles.deleteSuccess',
  },
);

sample({
  clock: deleteRole,
  source: roleDeleteModal.$store,
  fn: (roleId) => ({ input: { roleId } }),
  target: deleteRoleFx,
});

sample({
  clock: [deleteRoleFx.failData, deleteRoleFx.doneData],
  target: roleDeleteModal.resetModal,
});

// ----------------------- Updates -----------------------
sample({
  clock: deleteRoleFx.doneData,
  fn: () => ({
    input: {
      pagination: { limit: 50, skip: 0 },
    },
  }),
  target: roles.events.getRoles,
});

export const remove = {
  stores: {
    $pending: deleteRoleFx.pending,
  },
  events: {
    deleteRole,
  },
  roleDeleteModal,
};
