import { Box, Button, keyframes } from '@chakra-ui/react';
import { useUnit } from 'effector-react';
import { notificationsModel } from 'entities/Notification/model';
import { endPoints } from 'processes/Router/endPoints';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import IconNotification from 'shared/assets/svg/common/notification.svg?react';

const scaleAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
`;

const animation = `${scaleAnimation} 1s ease-in-out`;

export const Notification = () => {
  const navigate = useNavigate();
  const notifications = useUnit(
    notificationsModel.notifications.stores.$notifications,
  );
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    if (notifications) {
      setAnimationKey((prev) => prev + 1);
    }
  }, [notifications]);

  const onNotificationsClick = () => {
    navigate(endPoints.NOTIFICATIONS);
  };

  return (
    <Button
      onClick={onNotificationsClick}
      variant='unstyled'
      marginLeft='auto'
      position='relative'>
      <IconNotification width={20} height={20} />
      {notifications && (
        <Box
          key={animationKey} // Use key to remount and restart animation
          background='red'
          position='absolute'
          width={2}
          height={2}
          top={1}
          right={4}
          borderRadius='50%'
          animation={animation}
        />
      )}
    </Button>
  );
};
