export enum ENotificationType {
  TEST_TASK = 'TEST_TASK',
  ADD_PROJECT = 'ADD_PROJECT',
  CREATING_SUBNET = 'CREATING_SUBNET',
  CREATING_VPC = 'CREATING_VPC',
  CREATING_PROJECT = 'CREATING_PROJECT',
  CREATING_CONFIGURATION = 'CREATING_CONFIGURATION',
  PROJECT_CREATION_COMPLETE = 'PROJECT_CREATION_COMPLETE',
}
export interface IAddProjectNotification {
  title: string;
  description: string;
  timestamp: number;
}

export type TNotification = IAddProjectNotification;
