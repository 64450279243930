import { createEvent, sample } from 'effector';
import { profileModel } from 'entities/Profile/model';
import { rolesModel } from 'entities/Roles/model';
import { scopesModel } from 'entities/Scopes/model';
import { debounce } from 'patronum';
import { cache } from './cache';

const initialize = createEvent();
const reinitialize = createEvent();
const reset = createEvent();

sample({
  clock: [reinitialize, reset],
  target: [
    scopesModel.scopes.events.reinit,
    rolesModel.roles.events.reinit,
    profileModel.selfUser.events.reinit,
    cache.events.resetCache,
  ],
});

sample({
  clock: debounce({ source: reinitialize, timeout: 500 }),
  target: initialize,
});

sample({
  clock: initialize,
  target: [
    scopesModel.scopes.events.getScopes,
    rolesModel.roles.events.getRoles,
    profileModel.selfUser.events.getSelfUser,
    cache.events.initCache,
  ],
});

export const init = {
  events: {
    initialize,
    reinitialize,
    reset,
  },
};
