import IconCommission from 'shared/assets/svg/sidebar/commission.svg?react';
import IconDashboard from 'shared/assets/svg/sidebar/dashboard.svg?react';
import IconSettings from 'shared/assets/svg/sidebar/settings.svg?react';
import IconUser from 'shared/assets/svg/sidebar/user.svg?react';
import IconUsers from 'shared/assets/svg/sidebar/users.svg?react';
import IconServices from 'shared/assets/svg/sidebar/services.svg?react';
import IconBilling from 'shared/assets/svg/sidebar/billing.svg?react';

export const leftSidebarIcons = {
  users: <IconUsers />,
  access: <IconUser />,
  commission: <IconCommission />,
  dashboard: <IconDashboard />,
  settings: <IconSettings />,
  services: <IconServices />,
  billing: <IconBilling width={16} />,
};
