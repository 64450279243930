import { useUnit } from 'effector-react';
import { authModel } from 'entities/Auth/model';
import { socketModel } from 'entities/Socket/model';
import { useEffect } from 'react';

export const useSocket = () => {
  const [isAuth, socket] = useUnit([
    authModel.isAuth.stores.$isAuth,
    socketModel.init.stores.$socket,
  ]);

  useEffect(() => {
    if (isAuth && !socket?.connected) socketModel.init.events.initSocket();

    return () => {
      if (socket && !isAuth) socket?.disconnect();
    };
  }, [isAuth]);

  useEffect(() => {
    return () => {
      socket?.disconnect();
    };
  }, []);
};
