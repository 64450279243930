import React, { FC } from 'react';

import { IButtonProps } from './types';
import { colorConfig } from 'shared/ui/types';
import { buttonClass } from './style';
import { Button as ButtonComponent } from '@chakra-ui/react';
import { ThreeDotsPending } from 'shared/ui/molecules/ThreeDotsPending';

export const Button: FC<IButtonProps> = ({
  id = '',
  formId,
  color = 'primary',
  className = '',
  children,
  type = 'button',
  isDisabled = false,
  onClick = () => {},
  onContextMenu = () => {},
  size = 'md',
  props,
  pending = false,
}) => {
  return (
    <ButtonComponent
      colorScheme={colorConfig[color]}
      size={size}
      id={id}
      form={formId}
      type={type}
      data-size={size}
      className={`${buttonClass} ${className}`}
      onClick={onClick}
      onContextMenu={onContextMenu}
      isDisabled={pending || isDisabled}
      minWidth={100}
      {...props}>
      {pending ? <ThreeDotsPending /> : children}
    </ButtonComponent>
  );
};
