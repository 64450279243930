import { createEffect, createEvent, sample } from 'effector';

import { requestsClient } from 'shared/api/client';

import {
  DeleteScopeMutation,
  DeleteScopeMutationVariables,
} from 'shared/api/apollo/__generated__';
import { modalFactory } from 'shared/lib/modalFactory';
import { toastEffect } from 'shared/lib/toastEffect';
import { scopes } from './scopes';

// ----------------------- Delete scope -----------------------
const scopeDeleteModal = modalFactory<number>(null);

const deleteScope = createEvent();
const deleteScopeFx = toastEffect(
  createEffect<DeleteScopeMutationVariables, DeleteScopeMutation>(
    async (params) => {
      return await requestsClient.deleteScope(params);
    },
  ),
  {
    errorMessage: 'scopes.errors.delete',
    successMessage: 'scopes.deleteSuccess',
  },
);

sample({
  clock: deleteScope,
  source: scopeDeleteModal.$store,
  fn: (scopeId) => ({ input: { scopeId } }),
  target: deleteScopeFx,
});

sample({
  clock: [deleteScopeFx.failData, deleteScopeFx.doneData],
  target: scopeDeleteModal.resetModal,
});

// ----------------------- Updates -----------------------
sample({
  clock: deleteScopeFx.doneData,
  fn: () => ({
    input: {
      pagination: { limit: 50, skip: 0 },
    },
  }),
  target: scopes.events.getScopes,
});

export const remove = {
  stores: {
    $pending: deleteScopeFx.pending,
  },
  events: {
    deleteScope,
  },
  scopeDeleteModal,
};
