import { createEffect, createEvent, forward, sample } from 'effector';
import localForage from 'localforage';

import { ICacheFactory, ICacheFactoryResponse } from './types';

export const cacheFactory = <T>({
  $store,
  cacheKey,
  storageInstanceName,
  isPureStore = false,
  defaultValues = null,
}: ICacheFactory<T>): ICacheFactoryResponse<T> => {
  let storageInstance: LocalForage;

  if (storageInstanceName)
    storageInstance = localForage.createInstance({
      name: storageInstanceName,
    });

  // ------------------ Init logic -------------------
  const initCache = createEvent();
  const initCacheFx = createEffect<void, T>(async () => {
    if (storageInstanceName) return await storageInstance.getItem(cacheKey);
    const cache = await localForage.getItem(cacheKey);
    if (!cache && defaultValues) return defaultValues;
    return cache as T;
  });

  sample({
    clock: initCache,
    target: initCacheFx,
  });

  sample({
    clock: initCacheFx.doneData,
    filter: (data) => Boolean(data || data === 0) && isPureStore,
    target: $store,
  });

  // ------------------ Store logic -------------------
  const storeCacheFx = createEffect<T, void>(async (store) => {
    if (storageInstanceName) await storageInstance.setItem(cacheKey, store);
    else await localForage.setItem(cacheKey, store);
  });

  sample({
    clock: $store,
    target: storeCacheFx,
  });

  // ------------------ Reset logic -------------------
  const resetCache = createEvent();

  sample({
    clock: resetCache,
    target: $store.reinit,
  });

  return {
    initCache,
    initCacheFx,
    resetCache,
  };
};
