import {
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector';
import { debounce, reset } from 'patronum';
import { requestsClient } from 'shared/api/client';

import {
  GetScopesQueryVariables,
  Scope,
  Scopes,
} from 'shared/api/apollo/__generated__';
import { SEARCH_DEFAULT_DEBOUNCE_TIME } from 'shared/config/constants';

// ----------------------- Get scopes -----------------------
const $scopes = createStore<Scope[]>(null);
const $scopesCount = createStore(0);

const getScopes = createEvent();
const getScopesFx = createEffect<GetScopesQueryVariables, Scopes>(
  async (params) => {
    const res = await requestsClient.getScopes(params);
    return res.getScopes;
  },
);

sample({
  clock: getScopes,
  fn: () => ({
    input: {
      pagination: { limit: 50, skip: 0 },
    },
  }),
  target: getScopesFx,
});

sample({
  clock: getScopesFx.doneData,
  fn: (result) => result.nodes,
  target: $scopes,
});

sample({
  clock: getScopesFx.doneData,
  fn: (result) => result.count,
  target: $scopesCount,
});

// ----------------------- Search -----------------------
const setSearchInput = createEvent<string>();
const $searchInput = restore(setSearchInput, '');
const setSearchValue = debounce({
  source: $searchInput,
  timeout: SEARCH_DEFAULT_DEBOUNCE_TIME,
});

sample({
  clock: setSearchValue,
  fn: (search) => ({
    input: {
      title: search,
    },
  }),
  target: getScopesFx,
});

// ----------------------- Reset -----------------------
const reinit = createEvent();

reset({
  clock: reinit,
  target: [$scopes, $scopesCount, $searchInput],
});

export const scopes = {
  stores: {
    $scopes,
    $searchInput,
    $pending: getScopesFx.pending,
    $scopesCount,
  },
  events: {
    getScopes,
    setSearchInput,
    reinit,
  },
};
