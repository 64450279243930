import { PaginationState } from '@tanstack/react-table';

export const RANGE_LENGTH = 'errors.rangeLength';

export const REQUIRED = 'errors.required';

// localStorage const
export const ACCESS_TOKEN_KEY = '@accessToken';
export const REFRESH_TOKEN_KEY = '@refreshToken';
export const HASHED_PASSWORD = '@hashedPassword';
export const TABLE_ENTRIES_PER_PAGE = '@tableEntriesPerPage';

export const SEARCH_DEFAULT_DEBOUNCE_TIME = 500;
export const SEARCH_DEBOUNCE_TIMEOUT = 250;

export const DISPLAY_DECIMALS = 2;

export const PERMISSIONS = {
  USERS: {
    GET_USERS: 'GET_USERS',
    GET_USER: 'GET_USER',
    CREATE_USER: 'CREATE_USER',
    UPDATE_USER: 'UPDATE_USER',
  },
  ROLES: {
    GET_ROLES: 'GET_ROLES',
    GET_ROLE: 'GET_ROLE',
    CREATE_ROLE: 'CREATE_ROLE',
    UPDATE_ROLE: 'UPDATE_ROLE',
    DELETE_ROLE: 'DELETE_ROLE',
  },
  PERMISSIONS: {
    GET_PERMISSIONS: 'GET_PERMISSIONS',
    GET_PERMISSION: 'GET_PERMISSION',
    CREATE_PERMISSION: 'CREATE_PERMISSION',
    UPDATE_PERMISSION: 'UPDATE_PERMISSION',
    DELETE_PERMISSION: 'DELETE_PERMISSION',
  },
  SCOPES: {
    GET_SCOPES: 'GET_SCOPES',
    GET_SCOPE: 'GET_SCOPE',
    CREATE_SCOPE: 'CREATE_SCOPE',
    UPDATE_SCOPE: 'UPDATE_SCOPE',
  },
  PROFILE: {
    GET_USERS: 'GET_USERS',
    GET_USER: 'GET_USER',
    CREATE_USER: 'CREATE_USER',
    UPDATE_USER: 'UPDATE_USER',
    BLOCK_USER: 'BLOCK_USER',
    RESTORE_USER: 'RESTORE_USER',
  },
  NUTANIX: {
    GET_VMS: 'GET_NUTANIX_VMS',
    GET_PROJECTS: 'GET_NUTANIX_PROJECTS',
    ADD_PROJECT: 'ADD_NUTANIX_PROJECT',
  },
  TEST: {
    TEST: 'TEST',
  },
};

export const STORAGE_KEYS = {
  USERS_FILTERS: 'usersFilters',
  ROLES_FILTERS: 'rolesFilters',
  TRANSACTION_FILTERS: 'transactionFilters',
};

export const FILTER_TYPES = {
  PERMISSION_IDS: 'permissionIds',
  ROLE_IDS: 'roleIds',
};

export const paginationInitial: PaginationState = {
  pageIndex: 0,
  pageSize: 20,
};

export const UNAUTHORIZED = 'UNAUTHORIZED';
export const ACCESS_TOKEN_EXPIRED = 'ACCESS_TOKEN_EXPIRED';
