import { createEffect, createEvent, createStore, sample } from 'effector';
import { extractUserId } from 'entities/Auth/lib/extractUserId';
import { notificationsModel } from 'entities/Notification/model';
import { ENotificationType } from 'entities/Notification/types';
import { io, Socket } from 'socket.io-client';

const $socket = createStore<Socket>(null);

const initSocket = createEvent();
const initSocketFx = createEffect(async () => {
  const userId = extractUserId();

  const socket = io(import.meta.env.VITE_WS_SOCKET, {
    query: {
      userId,
    },
  });

  socket.on('connect', () => {
    console.log('Socket connected');
  });

  socket.on(ENotificationType.TEST_TASK, (event) => {
    console.log('Test task recieved', { event });
  });

  socket.on(ENotificationType.ADD_PROJECT, (event) => {
    notificationsModel.projects.events.addProjectNotification(
      JSON.parse(event),
    );
  });

  socket.on(ENotificationType.CREATING_CONFIGURATION, (event) => {
    notificationsModel.projects.events.addProjectNotification(
      JSON.parse(event),
    );
  });

  socket.on(ENotificationType.CREATING_SUBNET, (event) => {
    notificationsModel.projects.events.addProjectNotification(
      JSON.parse(event),
    );
  });

  socket.on(ENotificationType.CREATING_VPC, (event) => {
    notificationsModel.projects.events.addProjectNotification(
      JSON.parse(event),
    );
  });

  return socket;
});

sample({
  clock: initSocket,
  target: initSocketFx,
});

sample({
  clock: initSocketFx.doneData,
  target: $socket,
});

export const init = {
  stores: { $socket },
  events: { initSocket },
};
