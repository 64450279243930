import { createStore, sample } from 'effector';
import { ENotificationType, TNotification } from '../types';
import { cacheFactory } from 'shared/lib/cacheFactory';
import { CACHE_KEY_NOTIFICATIONS } from 'shared/config/cache';
import { projects } from './projects';

const $notifications =
  createStore<Record<ENotificationType, TNotification[]>>(null);

const {
  initCache: initNotificationsCache,
  resetCache: resetNotificationCache,
} = cacheFactory({
  $store: $notifications,
  cacheKey: CACHE_KEY_NOTIFICATIONS,
  isPureStore: true,
});

const $notificationsCount = createStore<number>(0).reset(
  resetNotificationCache,
);

sample({
  clock: projects.events.addProjectNotification,
  source: $notifications,
  fn: (notifications, notification) => ({
    ...notifications,
    [ENotificationType.ADD_PROJECT]: [
      ...(notifications.ADD_PROJECT ?? []),
      notification,
    ],
  }),
  target: $notifications,
});

export const notifications = {
  stores: { $notifications, $notificationsCount },
  events: {
    initNotificationsCache,
    resetNotificationCache,
  },
};
