import { createEvent, createStore, sample } from 'effector';
import { IAddProjectNotification } from '../types';

const addProjectNotification = createEvent<IAddProjectNotification>();
const $projectNotifications = createStore<IAddProjectNotification[]>([]);

sample({
  clock: addProjectNotification,
  source: $projectNotifications,
  fn: (notifications, notification) => [...notifications, notification],
  target: $projectNotifications,
});

export const projects = {
  stores: {
    $projectNotifications,
  },
  events: { addProjectNotification },
};
