import { createEffect, createEvent, createStore, sample } from 'effector';
import { logout } from 'entities/Auth/model/logout';
import jwtDecode from 'jwt-decode';
import { reset } from 'patronum';
import { Permission, User } from 'shared/api/apollo/__generated__/index';
import { requestsClient } from 'shared/api/client';
import { ACCESS_TOKEN_KEY } from 'shared/config/constants';
import { ITokenPayload } from 'shared/config/interfaces';

// ----------------------- Get self user -----------------------
const $selfUser = createStore<User>(null);
// TODO: Поменять логику
const $isSuperUser = createStore(false);

const getSelfUser = createEvent();
const getSelfUserFx = createEffect<void, User>(async () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const parsedToken = jwtDecode<ITokenPayload>(accessToken);
  if (!parsedToken) return null;
  const { getUser } = await requestsClient.getUser({
    input: {
      id: parsedToken.sub,
    },
  });
  return getUser as User;
});

sample({
  clock: getSelfUser,
  target: getSelfUserFx,
});

sample({
  clock: getSelfUserFx.doneData,
  target: $selfUser,
});

sample({
  clock: getSelfUserFx.doneData,
  fn: (selfUser) =>
    selfUser?.roles?.some((role) => role.role.title === 'SUSER'),
  target: $isSuperUser,
});
// TODO: Посмотреть почему вылетает на рефреш
// sample({
//   clock: getSelfUserFx.failData,
//   target: logout.events.logoutEvent,
// });
// ----------------------- Self permissions -----------------------
const $selfPermissions = createStore<Permission[]>(null);

sample({
  clock: $selfUser,
  fn: (user) => user.roles.flatMap((role) => role.role.permissions.nodes),
  target: $selfPermissions,
});

// ----------------------- Reset -----------------------
const reinit = createEvent();

reset({
  clock: reinit,
  target: [$selfUser, $selfPermissions],
});

export const selfUser = {
  stores: {
    $selfUser,
    $isSuperUser,
    $selfPermissions,
  },
  events: {
    getSelfUser,
    reinit,
  },
};
