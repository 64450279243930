import { threeDotsCss } from './style';

export const ThreeDotsPending = () => {
  return (
    <div className={threeDotsCss.wrapper}>
      <div className={threeDotsCss.dotsContainer}>
        <div className='dot' />
        <div className='dot' />
        <div className='dot' />
      </div>
    </div>
  );
};
