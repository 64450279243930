import {
  Box,
  CardBody,
  Card as CardComponent,
  CardHeader,
  Divider,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ICard } from './types';

export const Card: FC<ICard> = ({
  className = '',
  title,
  children,
  headerLeft,
  headerRight,
  headerBottom = null,
  headerDivider = true,
  props,
}) => {
  const { t } = useTranslation();
  return (
    <CardComponent>
      {(title || headerLeft || headerRight || headerBottom) && (
        <>
          <CardHeader>
            {title && (
              <Heading textAlign='center' size='md'>
                {t(title)}
              </Heading>
            )}
            <Flex alignItems='center'>
              {headerLeft && <Box>{headerLeft}</Box>}
              {headerRight && <Box marginLeft='auto'>{headerRight}</Box>}
            </Flex>
            {headerBottom && <Box>{headerBottom}</Box>}
          </CardHeader>
          {headerDivider && <Divider />}
        </>
      )}

      <CardBody minWidth='500px' {...props} className={className}>
        {children}
      </CardBody>
    </CardComponent>
  );
};
