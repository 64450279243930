import { endPoints } from 'processes/Router/endPoints';
import { TSearchMapItem } from '../types';

export const searchMap: TSearchMapItem[] = [
  {
    pathname: `${endPoints.USER}`,
    searchVariants: ['users'],
    title: 'Users',
  },
  {
    pathname: `${endPoints.ROLE}`,
    searchVariants: ['roles'],
    title: 'Roles',
  },
  {
    pathname: `${endPoints.ROLE_CREATE}`,
    searchVariants: ['roles create', 'create roles'],
    title: 'Create roles',
  },
];

export const defaultSearchMap: TSearchMapItem[] = [
  searchMap[0],
  searchMap[1],
  searchMap[2],
  searchMap[3],
];
