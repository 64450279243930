import { createEffect, createEvent, sample } from 'effector';
import { requestsClient } from 'shared/api/client';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'shared/config/constants';
import { extractUserId } from '../lib/extractUserId';

const logoutEvent = createEvent();
const logoutFx = createEffect<void, boolean>(async (params) => {
  const userId = extractUserId();
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  window.location.reload();
  const res = await requestsClient.logout({
    input: {
      userId: Number(userId),
    },
  });
  return res.logout;
});

sample({
  clock: logoutEvent,
  target: logoutFx,
});

export const logout = {
  events: {
    logoutEvent,
  },
};
