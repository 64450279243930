import React from 'react';

import { Breadcrumbs } from '../Breadcrumbs';
import Search from '../Search';

import { Flex } from '@chakra-ui/react';
import { Notification } from '../Notification';

export const Navbar: React.FC = () => {
  return (
    <Flex
      alignItems='center'
      justifyContent='space-between'
      minHeight='72px'
      background='white'
      paddingInline={6}>
      <Breadcrumbs />
      <Notification />
      <Search />
    </Flex>
  );
};
